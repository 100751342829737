/* eslint-disable import/prefer-default-export */
export const removeDash = str =>
  str
    .split('-')
    .map((part, i) =>
      i === 0
        ? part.charAt(0).toUpperCase() + part.slice(1)
        : part.charAt(0) + part.slice(1),
    )
    .join(' ');
