/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const animatedArrowRight = keyframes`
   0% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
`;

export const StyledDesktopNavigation = styled.nav`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  perspective: 600px;
  z-index: 999999;
  transition: all 0.2s ease-in;
  background-color: ${(props) => (props.scroll ? 'white' : 'transparent')};
  box-shadow: ${(props) =>
    props.scroll ? '1px -3px 10px #00000030' : 'transparent'};

  @media screen and (max-width: 959px) {
    display: none;
  }

  .link-container {
    display: flex;
    align-items: center;
  }

  .logo__container {
    display: flex;
    align-items: center;

    .company__logo {
      width: ${(props) =>
        props.scroll ? '130px !important' : '160px !important'};
      height: ${(props) => (props.scroll ? '80px' : ' 100px')};
      object-fit: cover;
      padding: 5px 0;
      transition: all 0.2s ease-in;
      margin-left: -8px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .links > li {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 10px;
  }
  .links > li > a {
    color: ${(props) => (props.scroll ? '#2196f3' : 'white')};
    text-decoration: none;
    font-size: 20px;
    margin: 30px 0;
    font-weight: 600;
    display: inline-block;
    text-shadow: ${(props) => (props.scroll ? 'unset' : '1px 1px 6px black;')};
    border-radius: 5px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 960px) and (max-width: 1279px) {
      font-size: 18px;
    }

    &.active {
      &:after {
        position: absolute;
        background: ${(props) => (props.scroll ? '#2196f3' : 'white')};
        width: 100%;
        left: 0;
      }
    }

    &:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 30px;
      content: '';
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: ${(props) => (props.scroll ? '#2196f3' : 'white')};
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }

    &:hover {
      i {
        animation: 1.5s ${animatedArrowRight} ease-in infinite;
      }
      &:after {
        background: ${(props) => (props.scroll ? '#2196f3' : 'white')};
        width: 100%;
        left: 0;
      }
    }
  }
  .dropdown {
    opacity: 0;
    position: absolute;
    /* top: ${(props) => (props.scroll ? '10px' : '-10px')}; */
    top: -13px;
    transition: all 0.4s;
    transform: translateY(100px);
    will-change: opacity;
    display: none;
    background-color: white;
    z-index: 4;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    li {
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      position: relative;
      z-index: 11;
      transition: all 0.2s ease-in;

      &:first-of-type {
        &:hover {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
      &:last-of-type {
        &:hover {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      &:hover {
        background-color: #eaeaef;

        i {
          animation: 1.5s ${animatedArrowRight} ease-in infinite;
        }
      }
    }
  }
  .trigger-enter .dropdown {
    display: block;
  }
  .trigger-enter-active .dropdown {
    opacity: 1;
  }

  .arrow {
    z-index: 1;
    position: absolute;
    top: -10px;
    left: 50%;
    width: 20px;
    height: 20px;
    display: block;
    background: white;
    transform: rotate(45deg);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }

  .courses {
    min-width: 250px;
  }

  .dropdown a {
    padding: 15px 0;
    text-decoration: none;
    color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.button {
    background: black;
    display: block;
    padding: 10px;
    color: white;
    margin-bottom: 10px;
  }
`;
