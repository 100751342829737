import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { useStaticQuery, graphql } from 'gatsby';

import { Container, Grid, Button } from '@material-ui/core';

import SocialIcons from '../SocialIcons';
import { StyledFooter } from './StyledFooter';

const Footer = () => {
  const { footerImg, favicon } = useStaticQuery(graphql`
    query {
      footerImg: file(relativePath: { eq: "bathroom/bathroom-1.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      favicon: file(relativePath: { eq: "favicon.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <StyledFooter className='footer' img={footerImg.childImageSharp.fluid.src}>
      <div className='footer__image'></div>
      <Container fixed className='footer__content'>
        <Grid container>
          <Grid item xs={12} md={4}>
            <h2>Over E S I - Installatietechniek B.V.</h2>
            <p className='mt-2x'>
              E S I garandeert u niets minder dan kwaliteit! <br />
              Met jaren lange ervaring en vakmanschap, van opdracht tot
              oplevering.
            </p>
            <div className='social-icons'>
              <SocialIcons className='mt-8x mt-md-4x' />
            </div>
            <ul className='mt-4x footer__extra'>
              <li>
                <p>E S I - Installatietechniek B.V. - 2020</p>
              </li>
              <li>
                <p>|</p>
              </li>
              <li>
                <AniLink fade to='/privacy'>
                  <p>Privacy</p>
                </AniLink>
              </li>
              <li>
                <p>|</p>
              </li>
              <li>
                <a href='https://derow.nl' target='_blank'>
                  <p>By: </p>
                  <img
                    src='https://images.prismic.io/derow-v1/2aad1da9-f63f-4033-8554-e3d57b0e27b8_a094a9e7-16e3-44ca-b567-41372901a4fc_Logo_with_name-small.png?auto=compress,format'
                    alt='Website ontwikkeld door Derow'
                  />
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className='center-xs'>
              <Img
                fixed={favicon.childImageSharp.fixed}
                objectFit='cover'
                className='mt-4x mt-md-0'
                alt='E S I - Installatietechniek B.V.'
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className='info'>
              <h2 className='mb-2x'>Neem direct contact op</h2>
              <p>Wilt u een offerte aanvragen of meer informatie?</p>
              <p>E S I - Installatietechniek B.V.</p>
              <p> Einsteinstraat 62, 1446 VG, Purmerend Nederland</p>
              <p>
                E-mail:
                <a
                  href='mailto:info@esi-install.nl'
                  style={{ textDecoration: 'underline' }}
                >
                  info@esi-install.nl
                </a>
              </p>
              <p>
                Telefoon:
                <a
                  href='tel:+31642090304'
                  style={{ textDecoration: 'underline' }}
                >
                  +31642090304
                </a>
              </p>
              <p>KVK: 81154089 </p>
              <p>BTW: NL861957763B01</p>
              <p>IBAN: NL82INGB0008389432</p>
              <div className='between-xs flex-row  w100'>
                <div className='hidden-sm hidden-md hidden-lg w100'>
                  <a href='tel:0642090304'>
                    <Button
                      role='button'
                      variant='contained'
                      className='btn heading__button mt-4x '
                    >
                      <i className='material-icons mr-1x'>phone</i>Krijg advies
                      op maat
                    </Button>
                  </a>
                </div>
                <AniLink fade to='/contact' className='hidden-xs'>
                  <Button
                    role='button'
                    variant='contained'
                    className='btn heading__button mt-1x '
                  >
                    <i className='material-icons mr-1x'>arrow_right</i>Kom in
                    contact
                  </Button>
                </AniLink>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
