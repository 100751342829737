/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  position: relative;
  padding: 32px 0;

  h2 {
    font-size: 25px;
  }

  p {
    font-size: 14px;
    margin: 0 0 8px !important;
  }

  .footer {
    &__image {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(${(props) => props.img});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
      filter: brightness(0.2);
    }

    &__content {
      position: relative;
      z-index: 2;
    }

    &__extra {
      padding: 0;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (min-width: 960px) and (max-width: 1279px) {
        width: 120%;
      }

      li {
        list-style: none;

        p,
        a {
          color: grey;
        }

        a {
          display: flex;
          align-items: center;
          img {
            height: 25px;
            width: auto;
          }
        }

        a {
          text-decoration: underline;

          &:hover {
            cursor: pointer;

            p {
              color: white;
            }
          }
        }
      }
    }
  }
`;
