/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledSingleListItem = styled.div`
  i {
    color: white;
  }

  .text-size {
    font-size: 18px;

    span {
      font-weight: bold;
    }
  }
`;
