import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';
import './index.scss';

const SocialIcons = ({ className }) => (
  <ul className={classNames('social-networks bounce', className)}>
    <li>
      <a
        href='https://nl.linkedin.com/in/egon-struijk-839445176?trk=people-guest_profile-result-card_result-card_full-click'
        className='icon-linkedin'
        target='_blank'
        rel='noopener noreferrer'
      >
        LinkedIn
      </a>
    </li>
    {/* <li>
      <Link href='/' className='icon-twitter'>
        Twitter
      </a>
    </li> */}
    <li>
      <a
        href='https://www.facebook.com/ESIInstall/'
        className='icon-facebook'
        target='_blank'
        rel='noopener noreferrer'
      >
        Facebook
      </a>
    </li>
    <li>
      <a
        href='https://www.instagram.com/esi_install'
        className='icon-instagram'
        target='_blank'
        rel='noopener noreferrer'
      >
        Instagram
      </a>
    </li>
  </ul>
);

SocialIcons.propTypes = {
  className: PropTypes.string,
};

export default SocialIcons;
