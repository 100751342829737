import React from 'react';
import { Location } from '@reach/router';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Footer from '../Footer';
import Nav from '../Nav';
import '../../styles/index.scss';

const Layout = ({ children }) => (
  <>
    <Helmet>
      <meta
        name='google-site-verification'
        content='CqbZ_gBmARUT20QhYNH595qUMCLV1y6Pa6sC5M4Wb2s'
      />
      <html lang='nl' />
    </Helmet>
    <Location>{locationProps => <Nav {...locationProps} />}</Location>
    <div className='offset-top'>{children}</div>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
