/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledDesktopNav = styled.div`
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in;
  background: linear-gradient(
    to bottom,
    ${props => (props.scroll ? '#000000' : 'transparent')},
    ${props => (props.scroll ? '#00000000' : 'transparent')}
  );

  .gatsby-image-wrapper {
    width: 100%;

    img {
      width: 120px !important;
      object-fit: contain !important;
    }
  }

  button {
    &:hover {
      background-color: transparent;
    }
  }

  @media screen and (min-width: 960px) {
    display: none;
  }
`;
