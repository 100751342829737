import React from 'react';
import PropTypes from 'prop-types';

import MobileNav from './MobileNav/MobileNav';
import DesktopNavigation from './DesktopNavigation';

const Nav = ({ location }) => (
  <>
    <DesktopNavigation location={location} />
    <MobileNav location={location} />
  </>
);

Nav.propTypes = {
  location: PropTypes.object,
};

export default Nav;
