// eslint-disable-next-line import/prefer-default-export
export const menu = [
  {
    name: 'loodgieterswerk',
    hasSubMenu: true,
    subMenu: ['loodgieter', 'waterontharders', 'boilers'],
    mobileSubMenu: [
      'loodgieterswerk',
      'loodgieter',
      'waterontharders',
      'boilers',
    ],
  },
  {
    name: 'sanitair',
    hasSubMenu: true,
    subMenu: ['over-sanitair', 'badkamer-en-toilet'],
    mobileSubMenu: ['sanitair', 'over-sanitair', 'badkamer-en-toilet'],
  },
  {
    name: 'verwarming',
    hasSubMenu: true,
    subMenu: [
      'over-verwarming',
      'thermostaten',
      'radiatoren',
      'vloerverwarming',
    ],
    mobileSubMenu: [
      'verwarming',
      'over-verwarming',
      'thermostaten',
      'radiatoren',
      'vloerverwarming',
    ],
  },
  {
    name: 'ventilatie',
    hasSubMenu: true,
    subMenu: ['mv-installatie', 'wtw-installatie', 'airco'],
    mobileSubMenu: ['ventilatie', 'mv-installatie', 'wtw-installatie', 'airco'],
  },
  {
    name: 'duurzaam',
    hasSubMenu: true,
    subMenu: ['over-duurzaam', 'zonneboilers', 'warmtepompen', 'pelletkachel'],
    mobileSubMenu: [
      'duurzaam',
      'over-duurzaam',
      'zonneboilers',
      'warmtepompen',
      'pelletkachel',
    ],
  },
  {
    name: 'contact',
    hasSubMenu: false,
  },
];
