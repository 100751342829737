import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const StyledDesktopNav = styled.div`
  .ham {
    z-index: 44;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ham.active {
    transform: rotate(45deg);
  }

  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #1b8ecf;
    stroke-width: 6;
    stroke-linecap: round;
  }

  .ham .top {
    stroke-dasharray: 40 160;
  }
  .ham .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
  }
  .ham .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
  }
  .ham.active .top {
    stroke-dashoffset: -64px;
  }
  .ham.active .middle {
    //stroke-dashoffset: -20px;
    transform: rotate(90deg);
  }
  .ham.active .bottom {
    stroke-dashoffset: -64px;
  }
`;

const HamburgerMenu = ({ active }) => (
  <StyledDesktopNav>
    <svg
      className={classNames('ham hamRotate', active && 'active')}
      viewBox='0 0 100 100'
      width='70'
    >
      <path
        className='line top'
        d='m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20'
      />
      <path className='line middle' d='m 30,50 h 40' />
      <path
        className='line bottom'
        d='m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20'
      />
    </svg>
  </StyledDesktopNav>
);

HamburgerMenu.propTypes = {
  active: PropTypes.bool,
};

export default HamburgerMenu;
